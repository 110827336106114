/* eslint-disable @nx/enforce-module-boundaries */
import { HStack, Stack, Box } from '@mybridge/ui/layout';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PostFormContext } from '../context';
import { Divider, IconButton, Textarea } from '@mybridge/ui';
import { PostAvatar } from 'v4/components/post-form/post-avatar';
import { PostVisibilityMenu } from 'v4/components/post-form/post-visibility-menu';
import { DeleteIcon } from '@mybridge/icons';
import { border } from '@chakra-ui/react';
import { AttachedMediaContainer } from '../attached-media-display';
import { AvailableMediaContainer } from '../available-media-display';
import { postDelete } from 'v4/store/actions/post.actions';
import { AvailableEditableMediaContainer } from '../available-editable-media-display';
import {
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  ContentState,
  SelectionState,
} from 'draft-js';
import { currentProfile } from 'v4/store/actions/user-profile.actions';
export const PostFormHeader = ({
  companyDetails,
  setOpenPostModal,
  placeholderText,
  multiPost,
  editingIndexHandler,
  editingIndex,
  isPostModalOpen,
}) => {
  const {
    expanded,
    setExpanded,
    feeling: feelings,
    setFeeling,
    setIsDisable,
    tagged,
    submitPost,
    mode,
    setText,
    images,
    gifs,
    videos,
    text,
    setGifs,
    setVideos,
    setImages,
    setTagged,
    setMultiPost,
    visibility,
    setVisibility,
    postEditorData,
    emojis,
    setEmojis,
    setPostEditorData,
    isCompany,
    setIsCompany,
    setShouldChange,
    shouldChange,
    EditorDefaultState,
  } = useContext(PostFormContext) ?? {};
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const textareaRef = useRef(null);

  const handleTextClick = (e) => {
    setExpanded(true);
    setOpenPostModal && setOpenPostModal(true);
  };

  const { feelingsAndActivitiesArr } = useSelector((s) => s.globalData) ?? {};
  const feeling = feelingsAndActivitiesArr?.find?.((v) => v.id == feelings?.id);
  const { currentProfile: selectedPostingProfile } = useSelector(
    (state) => state.userProfile
  );

  const [newTextState, setNewTextState] = useState(EditorState.createEmpty());
  useEffect(() => {
    setNewTextState(postEditorData);
  }, []);

  useEffect(() => {
    setPostEditorData(newTextState);
  }, [newTextState]);

  useEffect(() => {
    if (emojis.length > 0) {
      let stateEmoji = emojis;
      const emoj = stateEmoji.shift();

      const plainText = newTextState.getCurrentContent().getPlainText();
      const updatedText = plainText + emoj.native;
      const newContentState = ContentState.createFromText(updatedText);
      const newEditorState = EditorState.createWithContent(newContentState);
      setNewTextState(newEditorState);
      setPostEditorData(newEditorState);
      setText(updatedText);
      setEmojis(stateEmoji);
    }
  }, [emojis]);

  useEffect(() => {
    if (shouldChange) {
      setNewTextState(EditorDefaultState);
    }
    setShouldChange(false);
  }, [shouldChange]);

  // const handleTextChange = (e) => {
  //   // console.log('change text', e);
  //   // const text = contentBlock.getText();
  //   setText?.(text);
  //   // adjustHeight(event.target);
  // };

  const handleContentChange = (e, index) => {
    const newMultiPost = multiPost.map((item, i) => {
      if (i === index) {
        return { ...item, content: e.target.value };
      }
      return item;
    });
    setMultiPost(newMultiPost);
    adjustHeight(event.target);
  };

  const adjustHeight = (element) => {
    element.style.height = 'auto';
    element.style.height = element.scrollHeight + 'px';
  };

  useEffect(() => {
    if (textareaRef.current) {
      adjustHeight(textareaRef.current);
    }
  }, [text, multiPost]);

  // const displayText = text.slice(0, 250);
  // const extraText = text.slice(250);

  const contentDisplay = useCallback(
    (index) => {
      return multiPost[index].content?.slice(0, 250);
    },
    [multiPost]
  );

  const extraContentDisplay = useCallback(
    (index) => {
      if (multiPost[index].content?.slice(250)) {
        setIsDisable(true);
      } else {
        setIsDisable(false);
      }
      return multiPost[index].content?.slice(250);
    },
    [multiPost]
  );

  // const handleDeleteLastPost = () => {
  //   setMultiPost((prevPosts) => prevPosts.slice(0, -1));
  // };

  const handleEditingPost = (index) => {
    editingIndexHandler(index);
  };

  useEffect(() => {
    if (mode === 'edit' && (images?.length || gifs?.length || videos?.length)) {
      const newMultiPost = multiPost?.map((item, i) => {
        if (i === editingIndex) {
          return {
            ...item,
            media: images?.length ? images : videos.length ? videos : [],
            gif: gifs.length ? gifs[0].images?.original?.url : null,
          };
        }
        return item;
      });
      setMultiPost(newMultiPost);
    }
  }, [images, gifs, videos]);

  const handleDeleteLastPost = () => {
    if (mode === 'create') {
      setMultiPost((prevPosts) => {
        if (prevPosts.length > 0) {
          setText(prevPosts[prevPosts.length - 1].text);
          setImages(prevPosts[prevPosts.length - 1].images);
          setGifs(prevPosts[prevPosts.length - 1].gifs);
          setVideos(prevPosts[prevPosts.length - 1].videos);
          setTagged(prevPosts[prevPosts.length - 1].tagged);
          setVisibility(prevPosts[prevPosts.length - 1].visibility);
          setFeeling(prevPosts[prevPosts.length - 1].feeling);
          return prevPosts.slice(0, -1);
        }
        return prevPosts;
      });
    } else {
      setMultiPost((prevPosts) => {
        if (prevPosts.length > 0) {
          setText(prevPosts[prevPosts.length - 1].content || text);
          setImages([prevPosts[prevPosts.length - 1].media?.file] || images);
          setGifs(
            [
              {
                images: {
                  original: { url: prevPosts[prevPosts.length - 1].gif },
                },
              },
            ] || gifs
          );
          setVideos([prevPosts[prevPosts.length - 1].media?.file] || videos);
          setTagged(prevPosts[prevPosts.length - 1].tagged || tagged);
          setVisibility(
            prevPosts[prevPosts.length - 1].visibility || visibility
          );
          setFeeling(prevPosts[prevPosts.length - 1].feeling || feeling);
          return prevPosts.slice(0, -1);
        }
        return prevPosts;
      });
    }
  };
  // const updateEditorState =(newState) => {
  //   // const updatedEditors = [...postEditorData];
  //   // updatedEditors[index] = { id: index, editorState: newState };
  //   // setPostEditorData(updatedEditors);
  //   setPostEditorData(newState);
  // };

  useEffect(() => {
    if (companyDetails?.id) {
      dispatch(currentProfile(companyDetails));
      setIsCompany(true);
    } else {
      setIsCompany(false);
    }
  }, [companyDetails]);

  return (
    <Box className="postChildCreateContainer">
      {multiPost &&
        multiPost.length > 0 &&
        multiPost.map((field, index) => {
          return mode === 'create' ? (
            <Box key={field.id} pb="30px" className="postHasChildCreate">
              <HStack alignItems="flex-start">
                <PostAvatar
                  companyDetails={selectedPostingProfile}
                  multiPost={index ? multiPost : null}
                />
                <Stack
                  w="100%"
                  spacing={2}
                  justifyContent="flex-start"
                  overflow="hidden"
                >
                  <Box position="relative" w="100%">
                    <Box
                      as="div"
                      whiteSpace="pre-wrap"
                      wordBreak="break-word"
                      minH="50px"
                      fontSize="16px"
                      pt={placeholderText ? '0px' : '10px'}
                      pl="8px"
                      pr="8px"
                      bg="transparent"
                      borderRadius="xl"
                      maxH="64"
                      overflowY="hidden"
                      border={!setOpenPostModal ? 'none' : '1px solid #EFEFEF'}
                      onClick={handleTextClick}
                    >
                      <>
                        <Box
                          as="span"
                          fontFamily="Inter"
                          fontSize="16px"
                          fontWeight="400"
                          lineHeight="19.2px"
                          textAlign="left"
                          color="#5B5B5B"
                          opacity="40%"
                        >
                          {multiPost[index].text || multiPost[index].content}
                        </Box>
                      </>
                      <Stack overflowX="hidden" flex={1} opacity="40%">
                        <AvailableMediaContainer
                          images={
                            field?.images?.length ? field?.images : field?.media
                          }
                          videos={
                            field?.videos?.length ? field?.videos : field?.media
                          }
                          gifs={
                            field?.gifs?.length ? field?.gifs : [field?.gif]
                          }
                        />
                      </Stack>
                    </Box>
                  </Box>

                  {/* <Stack mt={1} display={expanded ? '' : 'none'}>
            <Box flexWrap="wrap">
              <PostFeelingAndTagsText tagged={tagged} feeling={feeling} />
            </Box>
            <HStack mt={2}>
              <PostVisibilityMenu />
            </HStack>
          </Stack> */}
                </Stack>
              </HStack>
              {/* {
                <Stack direction="row" h="60px" color="#E4E6EB" px={5} py={2}>
                  <Divider
                    orientation="vertical"
                    style={{ border: '2px solid #E4E6EB' }}
                  />
                </Stack>
              } */}
            </Box>
          ) : field.mode === 'edit' && multiPost?.length >= 1 ? (
            <HStack
              alignItems="flex-start"
              opacity={editingIndex === index ? '100%' : '40%'}
              onClick={() => handleEditingPost(index)}
            >
              <Box
                as="div"
                display="flex"
                flexDirection="column"
                alignItems="flex-center"
                justifyContent="flex-center"
              >
                <PostAvatar
                  companyDetails={selectedPostingProfile}
                  multiPost={index === 0 ? null : multiPost}
                />
                {index ? (
                  <IconButton
                    style={{ backgroundColor: 'transparent' }}
                    onClick={handleDeleteLastPost}
                  >
                    <DeleteIcon width={14} height={16} color="#5B5B5B" />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Box>
              <Stack
                w="100%"
                spacing={2}
                justifyContent="flex-start"
                overflow="hidden"
              >
                <Box position="relative" w="100%">
                  <Textarea
                    outline="none"
                    _focusVisible={{ outline: 'none' }}
                    h={'40px'}
                    bg="transparent"
                    autoFocus
                    resize="none"
                    contenteditable
                    onChange={(e) => handleContentChange(e, index)}
                    value={multiPost[index].content}
                    borderRadius="xl"
                    overflowY="hidden"
                    fontSize="16px"
                    pt={'15px'}
                    maxH={'40px'}
                    border={!setOpenPostModal ? 'none' : '1px solid #EFEFEF'}
                    placeholder={`What's on your mind ${
                      selectedPostingProfile
                        ? selectedPostingProfile?.name
                        : userProfileInfo?.first_name
                    }?`}
                    onClick={handleTextClick}
                    style={{
                      // position: 'absolute',
                      // top: 0,
                      // left: 0,
                      width: '100%',
                      // height: '100%',
                      zIndex: 1,
                      // opacity: 0,
                    }}
                  />
                  {/* <Box
                    as="div"
                    whiteSpace="pre-wrap"
                    wordBreak="break-word"
                    minH="50px"
                    fontSize="16px"
                    pt={placeholderText ? '0px' : '10px'}
                    pl="8px"
                    pr="8px"
                    bg="transparent"
                    borderRadius="xl"
                    maxH="64"
                    overflowY="hidden"
                    border={!setOpenPostModal ? 'none' : '1px solid #EFEFEF'}
                    onClick={handleTextClick}
                  >
                    {field?.content ? (
                      <>
                        <Box
                          as="span"
                          fontFamily="Inter"
                          fontSize="16px"
                          fontWeight="400"
                          lineHeight="19.2px"
                          textAlign="left"
                          color="#5B5B5B"
                        >
                          {contentDisplay(index)}
                        </Box>

                        {extraContentDisplay(index) && (
                          <Box
                            as="span"
                            bg="#FAD2D1"
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="400"
                            lineHeight="19.2px"
                            textAlign="left"
                            color="#5B5B5B"
                          >
                            {extraContentDisplay(index)}
                          </Box>
                        )}
                      </>
                    ) : multiPost?.length ? (
                      'Add another post'
                    ) : (
                      `What's on your mind ${
                        selectedPostingProfile
                          ? selectedPostingProfile?.name
                          : userProfileInfo?.first_name
                      }?`
                    )}
                  </Box> */}
                  <Stack overflowX="hidden" flex={1} zIndex={99}>
                    <AvailableEditableMediaContainer
                      itemIndex={index}
                      images={field?.media}
                      videos={field?.media}
                      gifs={[field?.gif]}
                    />
                  </Stack>
                </Box>
                <Stack mt={1} display={expanded ? '' : 'none'}>
                  <Box flexWrap="wrap">
                    {/* <PostFeelingAndTagsText tagged={tagged} feeling={feeling} /> */}
                  </Box>
                  <HStack mt={2}>{/* <PostVisibilityMenu /> */}</HStack>
                </Stack>
              </Stack>
            </HStack>
          ) : (
            <></>
          );
        })}
      {mode === 'create' || (mode === 'edit' && !multiPost) ? (
        <HStack alignItems="flex-start">
          <Box
            as="div"
            display="flex"
            flexDirection="column"
            alignItems="flex-center"
            justifyContent="flex-center"
          >
            <PostAvatar
              companyDetails={selectedPostingProfile}
              multiPost={multiPost?.length === 0 ? null : multiPost}
            />
            {multiPost?.length ? (
              <IconButton
                style={{ backgroundColor: 'transparent' }}
                onClick={handleDeleteLastPost}
              >
                <DeleteIcon width={14} height={16} color="#5B5B5B" />
              </IconButton>
            ) : (
              <></>
            )}
          </Box>
          <Stack
            w="100%"
            spacing={2}
            justifyContent="flex-start"
            overflow="hidden"
          >
            <Box position="relative" w="100%">
              <Box
                as="div"
                whiteSpace="pre-wrap"
                wordBreak="break-word"
                minH="50px"
                fontSize="16px"
                // pt={placeholderText ? '0px' : '10px'}
                // pl="8px"
                // pr="8px"
                bg="transparent"
                borderRadius="xl"
                // maxH="65px"
                overflowY="hidden"
                border={!setOpenPostModal ? 'none' : '1px solid #EFEFEF'}
                onClick={handleTextClick}
                className="postEditorContainer"
              >
                <Editor
                  // ref={textareaRef}
                  editorState={newTextState}
                  // onChange={setPostEditorData}
                  onChange={setNewTextState}
                  // onDelete={(newState) => updateEditorState(newState, 0)}
                  placeholder={
                    multiPost?.length
                      ? 'Add another post'
                      : `What's on your mind ${
                          selectedPostingProfile
                            ? selectedPostingProfile?.name
                            : userProfileInfo?.first_name
                        }?`
                  }
                />
                {isPostModalOpen && (
                  <Stack overflowX="hidden" flex={1} mt={4}>
                    <AttachedMediaContainer />
                  </Stack>
                )}
              </Box>
            </Box>
            <Stack mt={1} display={expanded ? '' : 'none'}>
              <Box flexWrap="wrap">
                {/* <PostFeelingAndTagsText tagged={tagged} feeling={feeling} /> */}
              </Box>
              <HStack mt={2}>{/* <PostVisibilityMenu /> */}</HStack>
            </Stack>
          </Stack>
        </HStack>
      ) : (
        <></>
      )}
    </Box>
  );
};
